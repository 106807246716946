import axios from 'axios'

import {
  fetchContractAndFacilityConfig,
  fetchFacility,
} from '../api/facilities'
import { fetchGooglePlacesCoordinates } from '../api/googlePlaces'
import { fetchCachedResource } from '../cacheBust/CacheBustUtils'
import { isEmpty } from '../external/utilities/GeneralUtils'
import {
  TTL_LONG_TERM_EXTREME,
  TTL_SHORT_TERM_EXTREME,
} from '../utilities/BackendCache'
import {
  batchFetchBackendData,
  batchFetchBackendDataWithCache,
  executeTxnsWithCallback,
  fetchBackendData,
  fetchBackendDataInline,
  fetchBackendDataInlineWithCache,
  getAxiosConfig,
  getConfigWithCache,
  getNoCacheParam,
  receiveData,
  receiveError,
  requestData,
  root,
  rootChub,
  sendToError,
  shortCircuitCache,
} from '../utilities/BackendUtils'
import { isProductDefined } from '../utilities/BookingUtils'
import { getCookie } from '../utilities/CookiesUtils'
import { FEEDBACK_GROUPS } from '../utilities/FeedbackUtils'
import {
  PRM,
  getCurrentPassesApiObj,
  getCurrentReservationsApiObj,
  getDisplayGISMapObj,
  getDynamicQueryObjURLToAPI,
  getEquipmentApiObj,
  getEquipmentIdFromQuery,
  getFeedbackObj,
  getLOBObj,
  getMainContractCodeFromQuery,
  getModeFromURLQuery,
  getNextAvailableDateApiObj,
  getPOSApiObj,
  getPOSObj,
  getPagingApiObj,
  getPassIdFromQuery,
  getPointsApiObj,
  getPrimaryItemIDApiObj,
  getProcessIdFromURLQuery,
  getProductAvailabilityApiObj,
  getProductCartApiObj,
  getProductReservationApiObj,
  getProductSearchApiObj,
  getProductSelectedDatesApiObj,
  getRateReservationApiObj,
  getReservationApiObj,
  getReservationIdFromQuery,
  getResetApiObj,
  getTempFacilityDetailsOnlyObj,
  isCurrentPassesMode,
  isCurrentReservationsMode,
  isDynamicUpdate,
  isNextAvailableDateApiObjMatch,
  isProductAvailabilityApiObjMatch,
  isProductSelectedDatesApiObjMatch,
  mergeQueries,
} from '../utilities/QueryParamUtils'
import { MAP_PROVIDERS } from '../utilities/consts'
import * as types from './ActionTypes'
import { setupAxiosInterceptors } from './setupAxiosInterceptors'

setupAxiosInterceptors()

/* Public Functions */
export function showLoader(isShow = true) {
  return function (dispatch, getState) {
    const { backend } = getState()
    if (backend.showLoader !== isShow) {
      dispatch(receiveData(isShow, types.SHOW_LOADER))
    }
  }
}

export function requestFeedback(
  feedbackGroup,
  cartDetailedProcessResult = null,
) {
  return function (dispatch) {
    dispatch(
      receiveData(
        [feedbackGroup, cartDetailedProcessResult],
        types.REQUEST_FEEDBACK,
      ),
    )
  }
}

export function requestRedirect(redirectConfig) {
  return function (dispatch) {
    dispatch(receiveData([redirectConfig], types.REQUEST_REDIRECT))
  }
}

export function getLocale(locale, isResolving = false) {
  return (dispatch) => {
    fetchCachedResource(`data/intl/locales/${locale}.json`)
      .then((locale) => {
        dispatch(receiveData(locale, types.GET_LOCALE))
      })
      .catch((err) => {
        dispatch(receiveError(err))

        if (isResolving) {
          sendToError(err)
        }
      })
  }
}

export function setRootInfo(rootInfo) {
  return {
    type: types.SET_ROOT_INFO,
    data: rootInfo,
  }
}

export function setRouteChangeActions() {
  return {
    type: types.ROUTE_CHANGE_ACTION,
    data: {},
  }
}

export function getAppInitData(rootInfo, locale, query, isResolving = false) {
  const intlTxn = fetchCachedResource(`data/intl/locales/${locale}.json`).catch(
    (err) => {
      throw { data: err.message }
    },
  )

  // const mainConfigURL = pre + '/config/main.json'
  const mainConfigTxn = fetchCachedResource('data/config/main.json').catch(
    (err) => {
      throw { data: err.message }
    },
  )

  const homeConfigTxn = fetchCachedResource('data/home/config_RA2.json').catch(
    (err) => {
      throw { data: err.message }
    },
  )

  const qObj = { [PRM.brand.api]: rootInfo.brandName }
  const brandConfigURL = `${root}/config/brand`
  const brandConfigURLTxn = axios(getAxiosConfig('get', brandConfigURL, qObj))

  let userURL = ''
  userURL = `${root}/users/0`
  const userTxn = axios(getAxiosConfig('get', userURL, getNoCacheParam()))

  const feedbackObj = getFeedbackObj({
    isURL: false,
    feedbackGroup: FEEDBACK_GROUPS.NONCHECKOUT,
  })
  const feedbackConfigURL = `${root}/feedback/survey`
  const feedbackConfigURLTxn = axios(
    getAxiosConfig('get', feedbackConfigURL, feedbackObj),
  )

  const metaConfigTxn = fetchCachedResource('data/config/meta_RA.json').catch(
    (err) => {
      throw { data: err.message }
    },
  )

  return batchFetchBackendData(
    [
      intlTxn,
      mainConfigTxn,
      homeConfigTxn,
      brandConfigURLTxn,
      userTxn,
      feedbackConfigURLTxn,
      metaConfigTxn,
    ],
    types.GET_APP_INIT,
    isResolving,
  )
}

export function sendFeedback(feedbackGroup, feedback, isResolving = false) {
  const feedbackObj = getFeedbackObj({
    isURL: false,
    feedbackGroup,
  })
  const feedbackURL = `${root}/feedback/survey`
  const feedbackTxn = axios(
    getAxiosConfig('post', feedbackURL, feedbackObj, feedback),
  )

  return batchFetchBackendData([feedbackTxn], types.SEND_FEEDBACK, isResolving)
}

export function getFacilityInitData(contractCode, facilityId) {
  return async (dispatch) => {
    dispatch(requestData(types.GET_FACILITY_INIT))

    const responses = await Promise.allSettled([
      fetchFacility(contractCode, facilityId, true),
      fetchContractAndFacilityConfig(contractCode, facilityId, true),
    ])

    dispatch(
      receiveData(
        responses.map(({ value }) => value?.data),
        types.GET_FACILITY_INIT,
      ),
    )

    const error = responses.find(({ reason }) => !!reason)?.reason

    if (error) {
      dispatch(facilityInitFailed(error))
      console.error(error)

      dispatch(receiveError(error.response.data))
    }
  }
}

export function facilityInitFailed(err) {
  return {
    type: types.GET_FACILITY_INIT_FAILED,
    data: err,
  }
}

export function uploadFacilityPhotoInline(
  contractCode,
  facilityId,
  userId,
  formObject,
  callback,
) {
  const formData = new FormData()
  formData.append('file', formObject.file)
  formData.append('title', formObject.title)
  formData.append('dateTaken', formObject.dateTaken)
  formData.append('description', formObject.description)
  const uploadFacilityPhotoURL = `${root}/facilities/${contractCode}/${facilityId}/ugc/${userId}`

  return fetchBackendDataInline(
    'post',
    uploadFacilityPhotoURL,
    null,
    formData,
    callback,
  )
}

export function updateSearchFilterObj(filterObj) {
  return function (dispatch) {
    dispatch(receiveData(filterObj, types.UPDATE_SEARCH_FILTER))
  }
}

/* Products */

export function getProductsInitData(
  contractCode,
  facilityId,
  query,
  isResolving = false,
) {
  let productsSearchURL = ''
  productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
  const apiQuery = {}
  mergeQueries(
    [
      getPagingApiObj(query),
      getProductAvailabilityApiObj(query),
      getProductSearchApiObj(query),
      getNextAvailableDateApiObj(query),
      getDisplayGISMapObj(true),
    ],
    apiQuery,
  )
  const productsSearchConfig = getConfigWithCache(
    'get',
    productsSearchURL,
    apiQuery,
    null,
    true,
    TTL_SHORT_TERM_EXTREME,
  )

  const lobInfoURL = `${root}/facilities/${contractCode}/${facilityId}/lobdetails/camping`
  const lobInfoConfig = getConfigWithCache(
    'get',
    lobInfoURL,
    null,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return batchFetchBackendDataWithCache(
    [productsSearchConfig, lobInfoConfig],
    types.GET_PRODUCTS_INIT,
    isResolving,
  )
}

export function updateSelectedProduct(productId, product = {}) {
  return {
    type: types.UPDATE_SELECTED_PRODUCT,
    data: {
      productId,
      product,
    },
  }
}

export function getProductsDayPassesInitData(
  contractCode,
  facilityId,
  query,
  isResolving = false,
) {
  const apiQuery = {}
  const searchObj = getDynamicQueryObjURLToAPI(query)
  mergeQueries([getLOBObj(false, 'dayentrance'), searchObj], apiQuery)

  const productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
  const productsSearchConfig = getConfigWithCache(
    'get',
    productsSearchURL,
    apiQuery,
    null,
    true,
    TTL_SHORT_TERM_EXTREME,
  )
  const lobInfoURL = `${root}/facilities/${contractCode}/${facilityId}/lobdetails/dayentrance`
  const lobInfoConfig = getConfigWithCache(
    'get',
    lobInfoURL,
    null,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return batchFetchBackendDataWithCache(
    [productsSearchConfig, lobInfoConfig],
    types.GET_PRODUCTS_DAYPASSES_INIT,
    isResolving,
  )
}

export function getProductsHourlySitesInitData(
  contractCode,
  facilityId,
  query,
  isResolving = false,
) {
  const apiQuery = {}
  const searchObj = getDynamicQueryObjURLToAPI(query)
  mergeQueries([getLOBObj(false, 'hourlyuse'), searchObj], apiQuery)

  const productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
  const productsSearchConfig = getConfigWithCache(
    'get',
    productsSearchURL,
    apiQuery,
    null,
    true,
    TTL_SHORT_TERM_EXTREME,
  )
  const lobInfoURL = `${root}/facilities/${contractCode}/${facilityId}/lobdetails/hourlyuse`
  const lobInfoConfig = getConfigWithCache(
    'get',
    lobInfoURL,
    null,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return batchFetchBackendDataWithCache(
    [productsSearchConfig, lobInfoConfig],
    types.GET_PRODUCTS_HOURLY_SITES_INIT,
    isResolving,
  )
}

/* Product Notification */
export function getProductProductNotificationInitData(
  contractCode,
  facilityId,
  productId = null,
  query = null,
  isResolving = false,
) {
  const txnArr = []

  let productsSearchURL = ''
  productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
  let apiQuery = {}
  if (isProductDefined(productId)) {
    apiQuery = {
      prdId: productId,
      detailed: true,
    }
  }
  mergeQueries(
    [getProductAvailabilityApiObj(query), getProductSearchApiObj(query)],
    apiQuery,
  )
  const productsSearchTxn = axios(
    getAxiosConfig('get', productsSearchURL, apiQuery),
  )
  txnArr.push(productsSearchTxn)

  return batchFetchBackendData(
    txnArr,
    types.GET_PRODUCT_NOTIFICATION_INIT,
    isResolving,
  )
}

export function createProductProductNotification(
  contractCode,
  facilityId,
  productId = null,
  query = null,
  isResolving = false,
) {
  const txnArr = []

  const productNotificationURL = `${root}/products/${contractCode}/${facilityId}/availabnotification`
  let apiQuery = {}
  if (isProductDefined(productId)) {
    apiQuery = { prdId: productId }
  }
  mergeQueries(
    [getProductAvailabilityApiObj(query), getProductSearchApiObj(query)],
    apiQuery,
  )
  const productsNotificationTxn = axios(
    getAxiosConfig('post', productNotificationURL, apiQuery),
  )
  txnArr.push(productsNotificationTxn)

  return batchFetchBackendData(
    txnArr,
    types.CREATE_PRODUCT_NOTIFICATION,
    isResolving,
  )
}

export function getProductAndBookingInitData(
  contractCode,
  facilityId,
  productId,
  query = null,
  oldQuery = null,
  isResolving = false,
  isSingleDay = false,
) {
  if (
    (oldQuery !== null &&
      isProductAvailabilityApiObjMatch(query, oldQuery) &&
      isNextAvailableDateApiObjMatch(query, oldQuery) &&
      isProductSelectedDatesApiObjMatch(query, oldQuery)) ||
    (oldQuery !== null &&
      isProductAvailabilityApiObjMatch(query, oldQuery) &&
      isNextAvailableDateApiObjMatch(query, oldQuery) &&
      !isProductSelectedDatesApiObjMatch(query, oldQuery) &&
      !isDynamicUpdate(query))
  ) {
    return function (dispatch, getState) {
      const { backend } = getState()
      const { facility } = backend.facility
      const contractConfig = backend.config.contract
      const facilityConfig = backend.config.facility1
      const contractAndFacilityConfig = {
        contractConfig,
        facilityConfig,
      }
      const { selectedProduct } = backend.productSearch
      const data = [facility, contractAndFacilityConfig, selectedProduct]
      dispatch(receiveData(data, types.GET_PRODUCT_AND_BOOKING_INIT))
    }
  } else if (
    oldQuery !== null &&
    isProductAvailabilityApiObjMatch(query, oldQuery) &&
    isNextAvailableDateApiObjMatch(query, oldQuery) &&
    !isProductSelectedDatesApiObjMatch(query, oldQuery) &&
    isDynamicUpdate(query)
  ) {
    const dynamicSiteDataURL = `${root}/products/${contractCode}/${facilityId}/dynamicsitedata`
    const productQuery = { prdId: productId }
    const productSelectedDatesQuery = getProductSelectedDatesApiObj(query)
    const apiQuery = {}

    mergeQueries([productQuery, productSelectedDatesQuery], apiQuery)
    const dynamicSiteDataTxn = axios(
      getAxiosConfig('get', dynamicSiteDataURL, apiQuery),
    )

    return batchFetchBackendData(
      [dynamicSiteDataTxn],
      types.UPDATE_PRODUCT_AND_BOOKING,
      isResolving,
    )
  }

  const txnArr = []

  let facilityURL = ''
  facilityURL = `${root}/facilities/${contractCode}/${facilityId}`
  const facilityTxn = getConfigWithCache(
    'get',
    facilityURL,
    getTempFacilityDetailsOnlyObj(false),
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )
  txnArr.push(facilityTxn)

  const contractAndFacilityConfigURL = `${root}/config/contractandfacility`
  const contractAndFacilityConfig = getConfigWithCache(
    'get',
    contractAndFacilityConfigURL,
    {
      contractCode,
      facilityID: facilityId,
    },
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )
  txnArr.push(contractAndFacilityConfig)

  let productsSearchURL = ''
  productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
  const apiQuery = {
    prdId: productId,
    detailed: true,
    gal: isSingleDay ? 1 : 14,
    fc: !isSingleDay,
  }
  const cartItemIdQuery = getProductCartApiObj(query)
  const reservationIdQuery = getProductReservationApiObj(query)
  const primaryItemIDQuery = getPrimaryItemIDApiObj(query)
  mergeQueries(
    [
      getProductAvailabilityApiObj(query),
      cartItemIdQuery,
      reservationIdQuery,
      primaryItemIDQuery,
      getProductSearchApiObj(query),
      getNextAvailableDateApiObj(query),
      getDisplayGISMapObj(true),
    ],
    apiQuery,
  )
  const productsSearchTxn = getConfigWithCache(
    'get',
    productsSearchURL,
    apiQuery,
    null,
    true,
    TTL_SHORT_TERM_EXTREME,
  )
  txnArr.push(productsSearchTxn)

  return batchFetchBackendDataWithCache(
    txnArr,
    types.GET_PRODUCT_AND_BOOKING_INIT,
    isResolving,
  )
}

/* Product Booking Details */
export function getProductBookingDetailsInitData(
  query = null,
  isResolving = false,
) {
  const getProductBookingOptionsURL = `${root}/shoppingcart/0/items/${query.cartItemId}/bookingDetails`
  const getProductBookingOptionsTxn = axios(
    getAxiosConfig('get', getProductBookingOptionsURL),
  )

  const getNext = function (dataArr) {
    const bookingDetails = dataArr[0]
    // TODO - this way of getting the cartItemId is incorrect - it will always give the first item ID. Fix this.
    const { contractCode } = bookingDetails.cartOrder
    const facilityId = bookingDetails.cartOrder.parkId

    const productsSearchURL = `${root}/products/${contractCode}/${facilityId}`
    const productsSearchTxn = axios(
      getAxiosConfig('get', productsSearchURL, getProductCartApiObj(query)),
    )

    return [productsSearchTxn]
  }

  return executeTxnsWithCallback(
    [getProductBookingOptionsTxn],
    types.GET_PRODUCT_DETAILS_INIT,
    getNext,
    isResolving,
  )
}

export function submitProductBookingDetailsSubForm(
  cartItemId,
  data,
  isResolving = false,
) {
  const submitProductBookingDetailsSubFormURL = `${root}/shoppingcart/0/items/${cartItemId}/findBookingDetails`
  const submitProductBookingDetailsSubFormTxn = axios(
    getAxiosConfig('post', submitProductBookingDetailsSubFormURL, null, data),
  )

  return batchFetchBackendData(
    [submitProductBookingDetailsSubFormTxn],
    types.SUBMIT_PRODUCT_BOOKING_DETAILS_SUB_FORM,
    isResolving,
  )
}

/* Product Booking Supplement Details */
export function getProductBookingSupplementDetailsInitData(
  query = null,
  isResolving = false,
) {
  // TODO - Validate and use QueryParamUtil for all query params used here
  const getProductBookingOptionsURL = `${root}/shoppingcart/0/items/${query.cartItemId}/otherBookingDetails`
  const getProductBookingOptionsTxn = axios(
    getAxiosConfig('get', getProductBookingOptionsURL),
  )

  return batchFetchBackendData(
    [getProductBookingOptionsTxn],
    types.GET_PRODUCT_BOOKING_SUPPLEMENT_DETAILS,
    isResolving,
  )
}

export function updateProductBookingSupplementDetails(
  cartItemId,
  data,
  isResolving = false,
) {
  const updateProductBookingSupplementDetailsURL = `${root}/shoppingcart/0/items/${cartItemId}/otherBookingDetails`
  const updateProductBookingSupplementDetailsTxn = axios(
    getAxiosConfig('put', updateProductBookingSupplementDetailsURL, null, data),
  )

  return batchFetchBackendData(
    [updateProductBookingSupplementDetailsTxn],
    types.UPDATE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS,
    isResolving,
  )
}

/* Cart */

export function addProductToCart(
  productParams,
  isResolving = false,
  userChallengeVerificationToken = null,
) {
  let addProductToCartURL = ''
  addProductToCartURL = `${root}/shoppingcart/0/additem`
  const addProductToCartTxn = axios(
    getAxiosConfig(
      'post',
      addProductToCartURL,
      null,
      productParams,
      userChallengeVerificationToken,
    ),
  )

  return batchFetchBackendData(
    [addProductToCartTxn],
    types.ADD_PRODUCT_TO_CART,
    isResolving,
  )
}

export function lockInventory(
  productParams,
  bypassAnonymousLock = false,
  isResolving = false,
  humanVerificationTokenV3 = '',
  humanVerificationActionV3 = '',
) {
  if (!bypassAnonymousLock) {
    const lockInventoryURL = `${root}/shoppingcart/anonymouslockitem`
    const lockInventoryTxn = axios(
      getAxiosConfig(
        'post',
        lockInventoryURL,
        null,
        productParams,
        '',
        humanVerificationTokenV3,
        humanVerificationActionV3,
      ),
    )

    return batchFetchBackendData(
      [lockInventoryTxn],
      types.LOCK_INVENTORY,
      isResolving,
    )
  }

  return (dispatch) => {
    dispatch(receiveData({}, types.LOCK_INVENTORY))
  }
}

export function updateCartItem(
  contractCode,
  facilityId,
  cartItemId,
  formData,
  isResolving = false,
  successfulCallback,
) {
  let reservationDetailUpdateURL = ''
  // reservationDetailUpdateURL = root + '/carts/0/items/' + cartItemId
  reservationDetailUpdateURL = `${root}/shoppingcart/0/items/${cartItemId}/bookingDetails`
  const updateCartItemTxn = axios(
    getAxiosConfig('post', reservationDetailUpdateURL, null, formData),
  )

  return batchFetchBackendData(
    [updateCartItemTxn],
    types.UPDATE_CART_ITEM,
    isResolving,
    successfulCallback,
  )
}

export function updateCartItemDates(
  cartId,
  cartItemId,
  bookingDatesRequest,
  isResolving = false,
) {
  const updateCartItemDateURL = `${root}/shoppingcart/${cartId}/items/${cartItemId}/bookingDates`
  const updateCartItemDateTxn = axios(
    getAxiosConfig('put', updateCartItemDateURL, null, bookingDatesRequest),
  )

  return batchFetchBackendData(
    [updateCartItemDateTxn],
    types.UPDATE_CART_ITEM_DATES,
    isResolving,
  )
}

export function removeCartItems(
  cartId,
  cartItemIDsToRemove,
  isResolving = false,
) {
  const itemIDArr = []
  for (const item of cartItemIDsToRemove) {
    const itemObj = { value: item }
    itemIDArr.push(itemObj)
  }
  let removeCartItemURL = ''
  removeCartItemURL = `${root}/shoppingcart/${cartId}/remove`
  const config = getAxiosConfig('post', removeCartItemURL, null, itemIDArr)
  const removeFromCartItemTxn = axios(config)

  return batchFetchBackendData(
    [removeFromCartItemTxn],
    types.REMOVE_CART_ITEMS,
    isResolving,
  )
}

export function addPOS(cartId, donationObj, isResolving = false) {
  const addPOSURL = `${root}/shoppingcart/${cartId}/addpos`
  const addPOSTxn = axios(getAxiosConfig('post', addPOSURL, null, donationObj))

  return batchFetchBackendData([addPOSTxn], types.ADD_POS, isResolving)
}

export function getCountriesData(isResolving = false) {
  return function (dispatch) {
    dispatch(requestData())

    const getCountryStateListURL = `${root}/config/resources/countries`

    const callback = function (success, response) {
      if (success) {
        dispatch(receiveData([undefined, response], types.GET_CHECKOUT_INIT))
      } else {
        dispatch(receiveError(response))
        if (isResolving) {
          sendToError()
        }
      }
    }

    fetchBackendDataInline('get', getCountryStateListURL, null, null, callback)
  }
}

export function getCheckoutInitData(isResolving = false) {
  return function (dispatch, getState) {
    dispatch(requestData())
    const { backend } = getState()
    let isGetCountryStateList = false
    if (isEmpty(backend.config.countryStateList)) {
      isGetCountryStateList = true
    }

    const checkoutInitURL = `${root}/shoppingcart/0/magazineoffer`
    const getCountryStateListURL = `${root}/config/resources/countries`
    let txnCtr = 0
    const data = []

    const callback = function (success, response) {
      if (success) {
        data.push(response)
        if (txnCtr === 2) {
          dispatch(receiveData(data, types.GET_CHECKOUT_INIT))
        }
        if (
          txnCtr === 1 &&
          response.offerRetrievalSuccess &&
          !isEmpty(response.magazineOffer) &&
          isGetCountryStateList
        ) {
          txnCtr += 1
          fetchBackendDataInline(
            'get',
            getCountryStateListURL,
            null,
            null,
            callback,
          )
        } else {
          dispatch(receiveData(data, types.GET_CHECKOUT_INIT))
        }
      } else {
        dispatch(receiveError(response))
        if (isResolving) {
          sendToError()
        }
      }
    }
    txnCtr += 1
    fetchBackendDataInline('get', checkoutInitURL, null, null, callback)
  }
}

export function getCart(isResolving = false) {
  let getCartURL = ''
  getCartURL = `${root}/shoppingcart/0`

  return fetchBackendData('get', types.GET_CART, getCartURL, isResolving)

  // // TODO - Testing only
  // let url = pre + '/test/confirmation.json'
  // let txn = axios(getAxiosConfig('get', url))
  // return batchFetchBackendData([txn], types.PROCESS_CART, isResolving)
}

export function verifyGiftCard(cartId, giftCardData, isResolving = false) {
  const verifyGCURL = `${root}/shoppingcart/${cartId}/verifygc`
  const verifyGCTxn = axios(
    getAxiosConfig('post', verifyGCURL, null, giftCardData),
  )

  return batchFetchBackendData(
    [verifyGCTxn],
    types.VERIFY_GIFT_CARD,
    isResolving,
  )
}

export function verifyGiftCardInline(cartId, giftCardData, callback) {
  const verifyGCURL = `${root}/shoppingcart/${cartId}/verifygc`

  return fetchBackendDataInline(
    'post',
    verifyGCURL,
    null,
    giftCardData,
    callback,
  )
}

export function getDetailedCartProcessResult(processId, isResolving = false) {
  const getCartProcessStatusURL = `${root}/shoppingcart/confirmations/${processId}`
  const getCartProcessStatusTxn = axios(
    getAxiosConfig('get', getCartProcessStatusURL, null, null),
  )

  const feedbackObj = getFeedbackObj({
    isURL: false,
    feedbackGroup: FEEDBACK_GROUPS.CHECKOUT,
  })
  const feedbackConfigURL = `${root}/feedback/survey`
  const feedbackConfigURLTxn = axios(
    getAxiosConfig('get', feedbackConfigURL, feedbackObj),
  )

  return batchFetchBackendData(
    [getCartProcessStatusTxn, feedbackConfigURLTxn],
    types.GET_DETAILED_CART_PROCESS_RESULT,
    isResolving,
  )
}

export function chubGetAddonOfferStatuses(data = []) {
  const promises = data
    .filter((item) => !!item?.paymentResponse?.status_url)
    .map(
      ({ paymentResponse }) =>
        new Promise((resolve) => {
          axios({
            url: `${rootChub}/cart/${paymentResponse.status_url.slice(1)}`,
            method: 'GET',
          })
            .then(resolve)
            .catch(({ response }) => resolve(response))
        }),
    )

  return batchFetchBackendData(
    promises,
    types.CHUB_GET_ADDON_OFFER_STATUSES,
    false,
    null,
    true,
  )
}

/* Offer */
export function getOffer(query = null, isResolving = false) {
  const processId = getProcessIdFromURLQuery(query)
  const getOffer = `${root}/shoppingcart/confirmations/${processId}/offer`

  return fetchBackendData('get', types.GET_OFFER, getOffer, isResolving)
}

export function processOffer(offer, processId, isResolving = false) {
  const processOfferURL = `${root}/shoppingcart/confirmations/${processId}/offer`
  const processOffer = axios(
    getAxiosConfig('post', processOfferURL, null, offer),
  )

  return batchFetchBackendData([processOffer], types.PROCESS_OFFER, isResolving)

  // let testSubmitFormTxn = axios(getAxiosConfig('post', processOfferURL, null, offer))
  // return batchFetchBackendData([testSubmitFormTxn], types.POST_FORM_TEST_DATA, isResolving)
}

/* POS */
export function getPOSItemInitData(query = null, isResolving = false) {
  const getPOSItemURL = `${root}/posproducts`
  const getPOSItemTxn = axios(
    getAxiosConfig('get', getPOSItemURL, getPOSApiObj(query)),
  )

  return batchFetchBackendData(
    [getPOSItemTxn],
    types.GET_POS_ITEM_INIT,
    isResolving,
  )
}

export function addPOSItemToCart(
  bundle,
  contractCode,
  data,
  isResolving = false,
) {
  const addPOSItemToCartURL = `${root}/shoppingcart/0/addpositems`
  const posObj = getPOSObj({
    isURL: false,
    bundle,
    mainContractCode: contractCode,
  })
  const addPOSItemToCartTxn = axios(
    getAxiosConfig('post', addPOSItemToCartURL, posObj, data),
  )

  return batchFetchBackendData(
    [addPOSItemToCartTxn],
    types.ADD_POS_ITEM_TO_CART,
    isResolving,
  )
}

export function addPendingPOSItemToCart(data) {
  return function (dispatch) {
    dispatch(receiveData(data, types.ADD_PENDING_POS_ITEM_TO_CART))
  }
}

/* Favorites */
export function getFavoritesInitData(query, isResolving = false) {
  const getFavoritesURL = `${root}/favorites`
  const getFavoritesTxn = axios(
    getAxiosConfig('get', getFavoritesURL, null, null),
  )
  const getItinerariesURL = `${root}/itineraries`
  const getItinerariesTxn = axios(
    getAxiosConfig('get', getItinerariesURL, null, null),
  )

  return batchFetchBackendData(
    [getFavoritesTxn, getItinerariesTxn],
    types.GET_FAVORITES_INIT,
    isResolving,
  )
}

export function getFavoritesChangeData() {
  return function (dispatch, getState) {
    const { backend } = getState()
    const { favoritesList } = backend.favorites
    const { lists } = backend.favorites
    const data = [favoritesList, lists]
    dispatch(receiveData(data, types.GET_FAVORITES_INIT))
  }
}

export function removeFacilityFromFavoritesInlineAndUpdate(
  contractCode,
  facilityId,
  callback,
) {
  const dataArr = []
  const onRemoveComplete = function (success, data) {
    if (success) {
      dataArr.push(data)
      const onGetFavoritesComplete = function (success, data) {
        if (success) {
          dataArr.push(data)
          const getItinerariesComplete = function (success, data) {
            if (success) {
              dataArr.push(data)
              callback(true, dataArr)
            } else {
              callback(success, data)
            }
          }
          const getItinerariesURL = `${root}/itineraries`
          fetchBackendDataInline(
            'get',
            getItinerariesURL,
            null,
            null,
            getItinerariesComplete,
          )
        } else {
          callback(success, data)
        }
      }
      const getFavoritesURL = `${root}/favorites`
      fetchBackendDataInline(
        'get',
        getFavoritesURL,
        null,
        null,
        onGetFavoritesComplete,
      )
    } else {
      callback(success, data)
    }
  }
  const deleteFavoritesURL = `${root}/facilities/${contractCode}/${facilityId}/favorites`
  fetchBackendDataInline(
    'delete',
    deleteFavoritesURL,
    null,
    null,
    onRemoveComplete,
  )
}

export function addFacilityToFavoritesInline(
  contractCode,
  facilityId,
  callback,
) {
  // clear data cache otherwise cached facility data will show incorrect favourite status
  shortCircuitCache()

  const getFavoritesURL = `${root}/favorites`

  return fetchBackendDataInline(
    'post',
    getFavoritesURL,
    null,
    {
      contractCode,
      facilityId,
    },
    callback,
  )
}

export function updateListNameInline(listId, updateListNameObj, callback) {
  const updateListNameURL = `${root}/itineraries/${listId}`

  return fetchBackendDataInline(
    'put',
    updateListNameURL,
    null,
    updateListNameObj,
    callback,
  )
}

export function addToFavoritesComplete() {
  return {
    type: types.ADD_TO_FAVORITES_COMPLETE,
    data: null,
  }
}

export function removeFromFavoritesComplete() {
  return {
    type: types.REMOVE_FROM_FAVORITES_COMPLETE,
    data: null,
  }
}

export function removeFacilityFromFavoritesInline(
  contractCode,
  facilityId,
  callback,
) {
  // clear data cache otherwise cached facility data will show incorrect favourite status
  shortCircuitCache()

  const deleteFavoritesURL = `${root}/facilities/${contractCode}/${facilityId}/favorites`

  return fetchBackendDataInline(
    'delete',
    deleteFavoritesURL,
    null,
    null,
    callback,
  )
}

export function addFavoriteToListsInline(
  favoriteId,
  addFavoriteToItinerariesInfo,
  callback,
) {
  const addFavoriteToListsURL = `${root}/favorites/${favoriteId}/itineraries/create`

  return fetchBackendDataInline(
    'post',
    addFavoriteToListsURL,
    null,
    addFavoriteToItinerariesInfo,
    callback,
  )
}

export function listAddFromFavoritesInline(
  listId,
  listsAddFromFavoritesObj,
  callback,
) {
  const listAddFromFavoritesURL = `${root}/itineraries/${listId}/favorites`

  return fetchBackendDataInline(
    'post',
    listAddFromFavoritesURL,
    null,
    listsAddFromFavoritesObj,
    callback,
  )
}

export function deleteFavoriteFromListInline(favoriteId, listId, callback) {
  const deleteFavoriteFromListURL = `${root}/itineraries/${listId}/items/${favoriteId}`

  return fetchBackendDataInline(
    'delete',
    deleteFavoriteFromListURL,
    null,
    null,
    callback,
  )
}

export function deleteListInline(listId, callback) {
  const deleteListURL = `${root}/itineraries/${listId}`

  return fetchBackendDataInline('delete', deleteListURL, null, null, callback)
}

export function createNewListInline(createNewListObj, callback) {
  const createNewListListURL = `${root}/itineraries`

  return fetchBackendDataInline(
    'post',
    createNewListListURL,
    null,
    createNewListObj,
    callback,
  )
}

export function reindexListInline(reindexListObj, listId, callback) {
  const editListListURL = `${root}/itineraries/${listId}/reindex`

  return fetchBackendDataInline(
    'put',
    editListListURL,
    null,
    reindexListObj,
    callback,
  )
}

export function editListInline(editListObj, listId, callback) {
  const editListListURL = `${root}/itineraries/${listId}/reindex/multiple`

  return fetchBackendDataInline(
    'put',
    editListListURL,
    null,
    editListObj,
    callback,
  )
}

export async function addPlaceToListInline(
  listId,
  placeObj,
  callback,
  searchProviderId = MAP_PROVIDERS.MAPBOX,
) {
  if (searchProviderId === MAP_PROVIDERS.GOOGLE) {
    const coords = await fetchGooglePlacesCoordinates(placeObj.googlePlaceId)

    if (coords) {
      placeObj.coordinates.latitude = coords.latitude
      placeObj.coordinates.longitude = coords.longitude
    }
  }

  return fetchBackendDataInline(
    'post',
    `${root}/itineraries/${listId}/places`,
    null,
    placeObj,
    callback,
  )
}

/* Contact Us */
export function getContactUsInitData(query, isResolving = false) {
  const clientConfigURL = `${root}/config/client`
  const clientConfigTxn = getConfigWithCache(
    'get',
    clientConfigURL,
    null,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return batchFetchBackendDataWithCache(
    [clientConfigTxn],
    types.GET_CONTACT_US_INIT,
    isResolving,
  )
}

/* Advertise */
export function getAdvertiseInitData(query, isResolving = false) {
  const advertiseInquiryURL = `${root}/advertising/inquiry/data`
  const advertiseInquiryTxn = getConfigWithCache(
    'get',
    advertiseInquiryURL,
    null,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return batchFetchBackendDataWithCache(
    [advertiseInquiryTxn],
    types.GET_ADVERTISE_INIT,
    isResolving,
  )
}

export function submitAdvertisingInquiryInline(data, callback) {
  const submitAdvertisingInquiryInlineURL = `${root}/advertising/inquiry/data`

  return fetchBackendDataInline(
    'post',
    submitAdvertisingInquiryInlineURL,
    null,
    data,
    callback,
  )
}

/* Info */
export function getInfoInitData(infoItemId, query, isResolving = false) {
  const txn = fetchCachedResource(`data/info/${infoItemId}.json`).catch(
    (err) => {
      throw { data: err.message }
    },
  )

  return batchFetchBackendData([txn], types.GET_INFO_INIT, isResolving)
}

/* My Account */
export const getDashboardInitData = function (isResolving = true) {
  const url = `${root}/account/overview`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_DASHBOARD_INIT, isResolving)
}

export const getReservationsInitData = async function (
  query = null,
  isResolving = true,
) {
  let txnType = types.GET_RESERVATIONS_CUURENT
  if (!isCurrentReservationsMode(query)) {
    txnType = types.GET_RESERVATIONS_PAST
  }

  const apiQuery = {
    [PRM.recordsPerPage.api]: 20,
    [PRM.reservationType.api]: 'Camping',
  }

  const defaultQueryParams = { curRsv: 'true', sortby: 'date', sortdir: 'asc' }

  const currentPaginationParams = getPagingApiObj(query)
  const currentQueryParams = getCurrentReservationsApiObj(query)

  mergeQueries([currentPaginationParams, currentQueryParams], apiQuery)

  const chubReservationsUrl = `${root}/account/myreservations`
  const chubOrdersUrl = `${rootChub}/order`
  const s2nReservationHistoryUrl = `${rootChub}/partner/reservation_history`

  const baseTxn = {
    records: [],
    query:
      Object.keys(currentQueryParams).length > 0
        ? currentQueryParams
        : defaultQueryParams,
    pagination: currentPaginationParams,
    endIndex: 16,
    startIndex: 0,
    totalPages: 1,
    totalRecords: 0,
    control: { currentPage: 0, pageSize: 20 },
  }

  let chubTxn = {}
  let s2nTxn = {}

  try {
    const chubPromises = [
      axios(getAxiosConfig('get', chubReservationsUrl, apiQuery, null)),
      axios(getAxiosConfig('get', chubOrdersUrl)),
    ]

    const [chubResponse, chubOrdersResponse] = await Promise.all(chubPromises)

    chubTxn = { ...chubTxn, ...chubResponse.data, ...chubOrdersResponse.data }
  } catch (err) {
    chubTxn = { ...baseTxn }
  }

  try {
    const s2nResponse = await axios(
      getAxiosConfig('get', s2nReservationHistoryUrl),
    )

    const parks = s2nResponse?.data?.map((pos) => [pos.parkID, pos])
    const uniqueParks = [...new Map(parks).values()]
    const parkCache = {}

    const parkDetailsPromises = uniqueParks.map((record) =>
      axios(
        getAxiosConfig(
          'get',
          `${rootChub}/partner/${record.partnerId}/park/${record.parkID}`,
        ),
      )
        .then((response) => ({
          status: 'fulfilled',
          parkID: record.parkID,
          data: response.data,
        }))
        .catch((error) => ({
          status: 'rejected',
          parkID: record.parkID,
          error,
        })),
    )
    const parkDetailsResults = await Promise.allSettled(parkDetailsPromises)

    parkDetailsResults.forEach((result) => {
      if (result.value.status === 'fulfilled') {
        parkCache[result.value.parkID] = result.value.data
      }
    })

    s2nTxn.records =
      s2nResponse?.data?.map((record) => {
        const parkDetails = parkCache[record.parkID]

        return {
          ...record,
          photoURL: parkDetails?.photos[0],
          parkDetails,
        }
      }) || []
  } catch (err) {
    s2nTxn = { ...baseTxn }
  }

  return batchFetchBackendData(
    [{ data: chubTxn }, { data: s2nTxn }],
    txnType,
    isResolving,
  )
}

export const getReservationDetailsInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const url = `${root}/account/myreservations/${reservationId}`
  const txn = axios(
    getAxiosConfig('get', url, getReservationApiObj(query), null),
  )

  return batchFetchBackendData(
    [txn],
    types.GET_RESERVATION_DETAILS_INIT,
    isResolving,
  )
}

export const getReservationCancelInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const url = `${root}/account/myreservations/${reservationId}/cancel`
  const ordersUrl = `${rootChub}/order`

  const txn = axios(
    getAxiosConfig('get', url, getReservationApiObj(query), null),
  )
  const orderTxn = axios(getAxiosConfig('get', ordersUrl))

  return batchFetchBackendData(
    [txn, orderTxn],
    types.GET_RESERVATION_CANCEL_INIT,
    isResolving,
  )
}

export const cancelReservation = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const url = `${root}/account/myreservations/${reservationId}/cancel/process`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData([txn], types.CANCEL_RESERVATION, isResolving)
}

export const getReservationPreCheckinInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const contractCode = getMainContractCodeFromQuery(query)
  const url = `${root}/account/myreservations/${contractCode}/${reservationId}/precheckin`
  const txn = axios(
    getAxiosConfig('get', url, getReservationApiObj(query), null),
  )

  return batchFetchBackendData(
    [txn],
    types.GET_RESERVATION_PRE_CHECKIN_INIT,
    isResolving,
  )
}

export const preCheckinReservation = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)

  // should go away when url is fixed
  const { contractCode } = reservationObj
  const url = `${root}/account/myreservations/${contractCode}/${reservationId}/precheckin`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData(
    [txn],
    types.PRE_CHECKIN_RESERVATION,
    isResolving,
  )
}

export const getReservationPaymentInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const url = `${root}/account/myreservations/${reservationId}/paybalance`
  const txn = axios(
    getAxiosConfig('get', url, getReservationApiObj(query), null),
  )

  return batchFetchBackendData(
    [txn],
    types.GET_RESERVATION_PAYMENT_INIT,
    isResolving,
  )
}

export const getReservationChangeInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const url = `${root}/account/myreservations/${reservationId}/change`
  const txnQuery = {}

  mergeQueries([getReservationApiObj(query), getResetApiObj(query)], txnQuery)
  const txn = axios(getAxiosConfig('get', url, txnQuery, null))

  return batchFetchBackendData(
    [txn],
    types.GET_RESERVATION_CHANGE_INIT,
    isResolving,
  )
}

export const changeReservation = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const url = `${root}/account/myreservations/${reservationId}/change/process`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData([txn], types.CHANGE_RESERVATION, isResolving)
}

export const changeReservationSiteDate = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const url = `${root}/account/myreservations/${reservationId}/changebooking`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData(
    [txn],
    types.CHANGE_RESERVATION_SITE_DATE,
    isResolving,
  )
}

export const cancelChangeReservation = function (
  reservationId,
  isResolving = false,
) {
  const url = `${root}/account/myreservations/${reservationId}/change/cancel`
  const txn = axios(getAxiosConfig('put', url, null, null))

  return batchFetchBackendData(
    [txn],
    types.CANCEL_CHANGE_RESERVATION,
    isResolving,
  )
}

export const getReservationChangeSupplementDetailsInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const url = `${root}/account/myreservations/${reservationId}/change/other`
  const txn = axios(
    getAxiosConfig('get', url, getReservationApiObj(query), null),
  )

  return batchFetchBackendData(
    [txn],
    types.GET_RESERVATION_CHANGE_SUPPLEMENT_DETAILS_INIT,
    isResolving,
  )
}

export const updateReservationSupplementDetails = function (
  reservationId,
  data,
  isResolving = false,
) {
  const updateReservationChangeSupplementDetailsURL = `${root}/account/myreservations/${reservationId}/change/other`
  const updateReservationChangeSupplementDetailsTxn = axios(
    getAxiosConfig(
      'put',
      updateReservationChangeSupplementDetailsURL,
      null,
      data,
    ),
  )

  return batchFetchBackendData(
    [updateReservationChangeSupplementDetailsTxn],
    types.UPDATE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS,
    isResolving,
  )
}

export const submitChangeReservationSubForm = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const submitChangeReservationSubFormURL = `${root}/account/myreservations/${reservationId}/change/findBookingDetails`
  const submitChangeReservationSubFormTxn = axios(
    getAxiosConfig(
      'post',
      submitChangeReservationSubFormURL,
      getReservationApiObj(reservationObj),
      data,
    ),
  )

  return batchFetchBackendData(
    [submitChangeReservationSubFormTxn],
    types.SUBMIT_CHANGE_RESERVATION_SUB_FORM,
    isResolving,
  )
}

export const getReservationChangeConfirmationInitData = function (
  query = null,
  isResolving = true,
) {
  const reservationId = getReservationIdFromQuery(query)
  const reservationUrl = `${root}/account/myreservations/${reservationId}/change/confirm`
  const ordersUrl = `${rootChub}/order`

  const reservationTxn = axios(
    getAxiosConfig('get', reservationUrl, getReservationApiObj(query), null),
  )
  const orderTxn = axios(getAxiosConfig('get', ordersUrl))

  return batchFetchBackendData(
    [reservationTxn, orderTxn],
    types.GET_RESERVATION_CHANGE_CONFIRMATION_INIT,
    isResolving,
  )
}

export const confirmChangeReservation = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const url = `${root}/account/myreservations/${reservationId}/change/confirm`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData(
    [txn],
    types.CONFIRM_CHANGE_RESERVATION,
    isResolving,
  )
}

export const payReservation = function (
  reservationObj,
  data,
  isResolving = false,
) {
  const reservationId = getReservationIdFromQuery(reservationObj)
  const url = `${root}/account/myreservations/${reservationId}/paybalance/process`
  const txn = axios(
    getAxiosConfig('post', url, getReservationApiObj(reservationObj), data),
  )

  return batchFetchBackendData([txn], types.PAY_RESERVATION, isResolving)
}

export const getNotificationsInitData = function (isResolving = true) {
  const url = `${root}/account/notifications`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_NOTIFICATIONS_INIT, isResolving)
}

export const removeNotification = function (
  notificationId,
  isResolving = false,
) {
  const removeNotificationURL = `${root}/account/notifications/${notificationId}/remove`
  const removeNotificationTxn = axios(
    getAxiosConfig('delete', removeNotificationURL, null, null),
  )

  const getNext = function () {
    const getNotificationsURL = `${root}/account/notifications`
    const getNotificationsTxn = axios(
      getAxiosConfig('get', getNotificationsURL, null, null),
    )

    return [getNotificationsTxn]
  }

  return executeTxnsWithCallback(
    [removeNotificationTxn],
    types.REMOVE_NOTIFICATION,
    getNext,
    isResolving,
  )
}

export const getApplicationsInitData = function (isResolving = true) {
  const url = `${root}/account/applications`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_APPLICATIONS_INIT, isResolving)
}

export const getPassesInitData = function (query = null, isResolving = true) {
  let txnType = types.GET_PASSES_CURRENT_INIT
  if (!isCurrentPassesMode(query)) {
    txnType = types.GET_PASSES_EXPIRED_INIT
  }

  const url = `${root}/account/discountpasses`
  const txn = axios(
    getAxiosConfig('get', url, getCurrentPassesApiObj(query), null),
  )

  return batchFetchBackendData([txn], txnType, isResolving)

  // const url = root + '/account/discountpasses',
  //   txn = axios(getAxiosConfig('get', url, null, null))
  //
  // return batchFetchBackendData([txn], types.GET_PASSES_INIT, isResolving)
}

export const getVouchersInitData = function (query, isResolving = true) {
  // Hack - fix later
  const apiQuery = { [PRM.recordsPerPage.api]: 10 }

  mergeQueries([getPagingApiObj(query)], apiQuery)
  const url = `${root}/account/vouchers`
  const txn = axios(getAxiosConfig('get', url, apiQuery, null))

  return batchFetchBackendData([txn], types.GET_VOUCHERS_INIT, isResolving)
}

export const getPointsInitData = function (isResolving = true) {
  const url = `${root}/account/loyalty`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_POINTS_INIT, isResolving)
}

export const getPointsDetailsInitData = function (
  query = null,
  isResolving = true,
) {
  // Hack - fix later
  const apiQuery = { [PRM.recordsPerPage.api]: 10 }

  mergeQueries([getPagingApiObj(query), getPointsApiObj(query)], apiQuery)

  const passId = getPassIdFromQuery(query)
  const url = `${root}/account/loyalty/${passId}`
  const txn = axios(getAxiosConfig('get', url, apiQuery, null))

  return batchFetchBackendData(
    [txn],
    types.GET_POINTS_DETAILS_INIT,
    isResolving,
  )
}

export const getEquipmentInitData = function (isResolving = true) {
  const url = `${root}/account/equipment`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_EQUIPMENT_INIT, isResolving)
}

export const getEquipmentDetailsInitData = function (
  query = null,
  isResolving = true,
) {
  const contractCode = getMainContractCodeFromQuery(query)
  const equipmentId = getEquipmentIdFromQuery(query)
  const url = `${root}/account/equipment/${contractCode}/${equipmentId}`
  const txn = axios(getAxiosConfig('get', url, getEquipmentApiObj(query), null))

  return batchFetchBackendData(
    [txn],
    types.GET_EQUIPMENT_DETAILS_INIT,
    isResolving,
  )
}

export const getPrintTicketsPermitsInitData = function () {
  // TODO
}

export const getProfileInitData = function (isResolving = true) {
  const url = `${root}/account/profile`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData([txn], types.GET_PROFILE_INIT, isResolving)
}

export const getCampClubProfileInitData = function (isResolving = true) {
  const url = `${root}/account/campingclub`
  const txn = axios(getAxiosConfig('get', url, null, null))

  return batchFetchBackendData(
    [txn],
    types.GET_CAMP_CLUB_PROFILE_INIT,
    isResolving,
  )
}

export const updateCampClubProfile = function (profile, isResolving = false) {
  const updateURL = `${root}/account/campingclub/update`
  const updateTxn = axios(getAxiosConfig('post', updateURL, null, profile))

  const getNext = function () {
    const getUpdatedURL = `${root}/account/campingclub`
    const getUpdatedTxn = axios(
      getAxiosConfig('get', getUpdatedURL, null, null),
    )

    return [getUpdatedTxn]
  }

  return executeTxnsWithCallback(
    [updateTxn],
    types.UPDATE_CAMP_CLUB_PROFILE,
    getNext,
    isResolving,
  )
}

export const getRateReservationInitData = function (
  query = null,
  isResolving = true,
) {
  const url = `${root}/feedback/ratings`
  const txn = axios(
    getAxiosConfig('get', url, getRateReservationApiObj(query), null),
  )

  return batchFetchBackendData(
    [txn],
    types.GET_RATE_RESERVATION_INIT,
    isResolving,
  )
}

export const rateReservation = function (ratingObj, isResolving = false) {
  const url = `${root}/feedback/ratings`
  const txn = axios(getAxiosConfig('post', url, null, ratingObj))

  return batchFetchBackendData([txn], types.RATE_RESERVATION, isResolving)
}

export const removeEquipment = function (
  contractCode,
  equipmentID,
  isResolving = false,
) {
  const deleteEquipmentURL = `${root}/account/equipment/${contractCode}/${equipmentID}/remove`
  const deleteEquipmentTxn = axios(
    getAxiosConfig('delete', deleteEquipmentURL, null, null),
  )

  const getNext = function () {
    const getEquipmentURL = `${root}/account/equipment`
    const getEquipmentTxn = axios(
      getAxiosConfig('get', getEquipmentURL, null, null),
    )

    return [getEquipmentTxn]
  }

  return executeTxnsWithCallback(
    [deleteEquipmentTxn],
    types.REMOVE_EQUIPMENT,
    getNext,
    isResolving,
  )
}

export const updateEquipment = function (
  contractCode,
  equipmentID,
  details,
  isResolving = false,
) {
  const updateEquipmentURL = `${root}/account/equipment/${contractCode}/${equipmentID}/update`
  const updateEquipmentTxn = axios(
    getAxiosConfig('post', updateEquipmentURL, null, details),
  )

  return batchFetchBackendData(
    [updateEquipmentTxn],
    types.UPDATE_EQUIPMENT,
    isResolving,
  )
}

export const requestConfirmationEmail = function (
  contractCode,
  reservationId,
  isResolving = false,
) {
  const requestConfirmationEmailURL = `${root}/account/myreservations/${contractCode}/${reservationId}/reqconfemail`
  const requestConfirmationEmailTxn = axios(
    getAxiosConfig('get', requestConfirmationEmailURL, null, null),
  )

  return batchFetchBackendData(
    [requestConfirmationEmailTxn],
    types.REQUEST_CONFIRMATION_EMAIL,
    isResolving,
  )
}

export const getDestinationsProductList = function (
  destinationsSearchObj,
  callback,
) {
  const getDestinationsProductListURL = `${root}/destinations/destination-products`
  const destinationsProductListTxn = getConfigWithCache(
    'get',
    getDestinationsProductListURL,
    destinationsSearchObj,
    null,
    true,
    TTL_LONG_TERM_EXTREME,
  )

  return fetchBackendDataInlineWithCache(destinationsProductListTxn, callback)
}

export const addSignupInfo = function (signupObj, callback) {
  const addSignupInfoURL = `${root}/external/newsletters/subscribe`

  return fetchBackendDataInline(
    'post',
    addSignupInfoURL,
    null,
    signupObj,
    callback,
  )
}

export function launchDataUpdateSkip() {
  return (dispatch) => {
    dispatch(receiveData({}, types.LAUNCH_DATA_UPDATE_SKIP))
  }
}

/* List Campground */
export function getListCampgroundInitData(isResolving = false) {
  const txn = fetchCachedResource('data/config/listcampground.json').catch(
    (err) => {
      throw { data: err.message }
    },
  )

  return batchFetchBackendData(
    [txn],
    types.GET_LIST_CAMPGROUND_INIT,
    isResolving,
  )
}

export function createCampgroundOwnerAcct(
  createCgOwnerAcctObject,
  isResolving = false,
) {
  const createCgOwnerAcctURL = `${root}/campowner/signup`
  const createCgOwnerAcctTxn = axios(
    getAxiosConfig('post', createCgOwnerAcctURL, null, createCgOwnerAcctObject),
  )

  return executeTxnsWithCallback(
    [createCgOwnerAcctTxn],
    types.CREATE_CG_OWNER_ACCT,
    null,
    isResolving,
  )
}

/* Testing */
export function getFormTestData(query = null, isResolving = false) {
  const url = `${root}/resource/formdata`
  const mode = getModeFromURLQuery(query)

  const txn = axios(getAxiosConfig('get', url, { mode }))

  const urlDynamicContent = `${root}/resource/dynamiccontent`

  const txnDynamicContent = axios(getAxiosConfig('get', urlDynamicContent))

  return batchFetchBackendData(
    [txn, txnDynamicContent],
    types.GET_FORM_TEST_DATA,
    isResolving,
  )
}

export function testSubmitForm(data, isResolving = false) {
  const testSubmitFormURL = `${root}/resource/formdata`
  const testSubmitFormTxn = axios(
    getAxiosConfig('post', testSubmitFormURL, null, data),
  )

  return batchFetchBackendData(
    [testSubmitFormTxn],
    types.POST_FORM_TEST_DATA,
    isResolving,
  )
}

export function onTestSubmitSubForm(data, isResolving = false) {
  const testSubmitSubFormURL = `${root}/resource/subformdata`
  const testSubmitSubFormTxn = axios(
    getAxiosConfig('post', testSubmitSubFormURL, null, data),
  )

  return batchFetchBackendData(
    [testSubmitSubFormTxn],
    types.TEST_SUBMIT_SUB_FORM,
    isResolving,
  )
}

/* Testing */
export function getPageOneData(isResolving = false) {
  return (dispatch) => {
    fetchCachedResource('mock/test/pageonedata.json')
      .then((data) => {
        dispatch(receiveData(data, types.GET_PAGE_ONE_DATA))
      })
      .catch((err) => {
        dispatch(receiveError(err))
        if (isResolving) {
          sendToError(err)
        }
      })
  }
}

/* Testing */
export function getPageTwoData(data, dataPlus, isResolving = false) {
  const dataTxn = fetchCachedResource(`mock/test/${data}.json`).catch((err) => {
    throw { data: err.message }
  })

  const dataPlusTxn = fetchCachedResource(`data/test/${dataPlus}.json`).catch(
    (err) => {
      throw { data: err.message }
    },
  )

  return batchFetchBackendData(
    [dataTxn, dataPlusTxn],
    types.GET_PAGE_TWO_DATA,
    isResolving,
  )
}

/* Facility Search */
export function updateProductSearchCriteria(searchCriteria) {
  return {
    type: types.UPDATE_PRODUCT_SEARCH_CRITERIA,
    data: searchCriteria,
  }
}

// ------- Camperhub -----------
export function updateCamperHubFacility(data) {
  return {
    type: types.UPDATE_CAMPER_HUB_FACILITY,
    data,
  }
}

export function updateCamperHubFacilityAvailableDates(data) {
  return {
    type: types.UPDATE_CAMPER_HUB_FACILITY_AVAILABLE_DATES,
    data,
  }
}

export function getCamperHubFacilityData(name, facilityId) {
  const facilityDataTxn = axios({
    url: `${rootChub}/park_lookup/${name}/${facilityId}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const facilityEnumsTxn = axios({
    url: `${rootChub}/partner/enumerations`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData(
    [facilityDataTxn, facilityEnumsTxn],
    types.UPDATE_CAMPER_HUB_FACILITY,
  )
}

export function resetCamperHubFacilityData(dispatch) {
  return function () {
    dispatch({
      type: types.UPDATE_CAMPER_HUB_FACILITY,
    })
  }
}

export function resetProductSearchCriteria() {
  return { type: types.RESET_PRODUCT_SEARCH_CRITERIA }
}

export function chubClaimCart(cartId) {
  const claimCartTxn = axios({
    url: `${rootChub}/cart/${cartId}`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData([claimCartTxn], types.CHUB_CLAIM_CART)
}

export function chubAddItemToCart(cartId, item) {
  const addItemToCartTxn = axios({
    url: `${rootChub}/cart/${cartId}/items`,
    method: 'post',
    data: { items: Array.isArray(item) ? item : [item] },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData([addItemToCartTxn], types.CHUB_ADD_ITEM_TO_CART)
}

export function chubRemoveItemFromCart(cartId, itemId) {
  const removeItemFromCartTxn = axios({
    url: `${rootChub}/cart/${cartId}/items/${itemId}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData(
    [removeItemFromCartTxn],
    types.CHUB_REMOVE_ITEM_FROM_CART,
  )
}

export function chubGetLatestCartFromCurrentUser() {
  const a1SessionId = getCookie('a1Data').sessionID

  const getLatestCartFromCurrentUserTxn = axios({
    url: `${rootChub}/cart/latest`,
    method: 'get',
    headers: {
      a1SessionId,
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData(
    [getLatestCartFromCurrentUserTxn],
    types.CHUB_GET_LATEST_CART_FROM_CURRENT_USER,
  )
}

export function chubDeclineQuote(data) {
  const chubDeclineQuote = axios({
    url: `${rootChub}/weather-guarantee/quote/${data}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData([chubDeclineQuote], types.CHUB_DECLINE_QUOTE)
}

export function chubGetCartConfig() {
  const getChubCartConfig = axios({
    url: `${rootChub}/config`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData(
    [getChubCartConfig],
    types.CHUB_GET_CART_CONFIG,
    false,
    null,
    true,
  )
}

export function chubToggleCart(chubCartConfig) {
  return {
    type: types.CHUB_TOGGLE_CART,
    data: chubCartConfig,
  }
}

export function chubToggleCartModal(isVisible) {
  return {
    type: types.CHUB_TOGGLE_CART_MODAL,
    data: isVisible,
  }
}

export function setEvaultWasMounted(isMounted) {
  return {
    type: types.SET_EVAULT_WAS_MOUNTED,
    data: isMounted,
  }
}

export function chubGetApexClubSubscriptionStatus() {
  const getApexClubSubscriptionStatus = axios({
    url: `${rootChub}/apex/status`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return batchFetchBackendData(
    [getApexClubSubscriptionStatus],
    types.CHUB_GET_APEX_CLUB_STATUS,
  )
}

export function getAvailableChubSpots(partner, parkId, query) {
  return axios({
    url: `${rootChub}/partner/${partner}/park/${parkId}/availability`,
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export async function checkoutS2nRegister(billingData, agencyName, partnerId) {
  if (agencyName === 'Firefly Agency') {
    return null
  }
  const response = await axios({
    url: `${rootChub}/partner/${partnerId}/register`,
    method: 'post',
    data: {
      billing: billingData.billing,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function getPaymentMethods(partnerId, reservationId) {
  const response = await axios({
    url: `${rootChub}/partner/${partnerId}/payment-methods?reservationId=${reservationId}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function createChubCart({ isAnonymous = false, items = [] }) {
  const response = await axios({
    url: `${rootChub}/cart`,
    method: 'post',
    data: {
      isAnonymous,
      cartItems: items,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function updateChubCart(cartId, { items, isAnonymous, userId }) {
  const response = await axios({
    url: `${rootChub}/cart/${cartId}/items`,
    method: 'post',
    data: {
      items,
      isAnonymous,
      userId,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function getChubCart(cartId) {
  const response = await axios({
    url: `${rootChub}/cart/${cartId}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function getChubReservationQuote(
  partnerId,
  parkId,
  spotId,
  query,
) {
  const response = await axios({
    url: `${rootChub}/partner/${partnerId}/park/${parkId}/${spotId}/quote`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data: query,
  })

  return response.data
}

export async function submitChubCheckout(
  partner,
  parkId,
  spotId,
  data,
  humanVerificationToken,
) {
  const headers = { 'Content-Type': 'application/json' }

  if (humanVerificationToken) {
    headers.humanverificationtoken = humanVerificationToken
  }

  const response = await axios({
    url: `${rootChub}/partner/${partner}/park/${parkId}/${spotId}/create_reservation`,
    method: 'post',
    headers,
    data,
  })

  return response.data
}

export function setAvailableSWQuotes(quote) {
  return {
    type: types.SET_AVAILABLE_SW_QUOTES,
    data: quote,
  }
}

export function updateChubCartResponse(response) {
  return {
    type: types.UPDATE_CHUBCART_RESPONSE,
    data: response,
  }
}

export function declineUnpurchasedSWQuotes(quotes) {
  const requests = quotes.map((quoteId) =>
    axios({
      url: `${rootChub}/weather-guarantee/quote/${quoteId}`,
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  )

  return batchFetchBackendData(
    [Promise.all(requests)],
    types.DECLINE_UNPURCHASED_SW_QUOTES,
  )
}

export async function getCamperHubFacilityAvailableDates(partnerId, parkId) {
  const response = await axios({
    url: `${rootChub}/partner/${partnerId}/park/${parkId}/calendar`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export async function getConfirmationStatus(partner, reservationId) {
  const response = await axios({
    url: `${rootChub}/partner/${partner}/reservations/${reservationId}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export function setUser(data) {
  return {
    type: types.USER_DATA,
    data,
  }
}

export async function authenticate(body) {
  const response = await axios({
    url: `${root}/authenticate`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
  })

  return response.data
}
